import React from "react";
import { Link } from "gatsby";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import SmallContactForm from "../components/small-contact-form";
import TagsList from "../components/tags-list";
import SharePopupModel from "../components/share-popup-model";
import TableOfContent from "../components/table-of-content";
import InnerSnipCard from "../components/inner-snip-card";
import FormResponseModel from "../components/form-response-model";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import globe from "../globals";
import PopupModel from "../components/popup-model";
import FixedInfoButton from "../components/FixedInfoButton";

export default function Post({ pageContext }) {
  const { post } = pageContext;
  console.log(post);
  let q = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  const r1 = /<h\d.+?<\/h\d>/g;
  const r2 = /<.+?>/gi;
  let t = post.content.match(r1) || [];
  let toc = t.map((e) => e.toString().replace(r2, ""));

  let ids = toc.map((e) =>
    e
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "")
  );

  let content = post.content;

  t.forEach((e, i) => {
    let t2 = e.substr(0, 3) + ' id="' + ids[i] + '" ' + e.substr(3, e.length);
    content = content.replace(e, t2);
  });

  let tit = encodeURI(post.title) || "";
  let url = encodeURI(
    q.site.siteMetadata.siteUrl + new URL(post.link).pathname
  );

  let yoast_head = globe.domainReplace(post.yoast_head);
  let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || "";
  let schema =
    temp !== undefined && temp !== null && temp.length > 70
      ? temp.substring(62, temp.length - 9)
      : {};

  let des = post.excerpt.substr(3, post.excerpt.length - 8) || "";

  return (
    <>
      <Helmet>
        <html lang="en" />
        {/* <title>{post.title}</title> */}
        <title>{post.title}</title>

        {post.yoast_head.match(/name="description"/g) === null ? (
          <meta name="description" content={des} />
        ) : null}
        {ReactHtmlParser(yoast_head)}

        <script type="application/ld+json">{schema}</script>
      </Helmet>
      <Header />

      <Menu />

      <main>
        <div className="container-fluid">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="pos-sticky scrollspy-left-container mb-4 mb-lg-0"
                  style={{ backgroundColor: "#f7f7f7" }}
                >
                  <h6 className={"mb-3"}>Table of Content</h6>
                  <TableOfContent toc={toc} ids={ids} key={3} />
                </div>
              </div>
              <div className="col-lg-9">
                <div className="container-fluid px-0">
                  <div className="row">
                    <div className="col-lg-8">
                      <nav aria-label="breadcrumb">
                        <ol
                          className="breadcrumb"
                          style={{
                            backgroundColor: "transparent",
                            paddingLeft: "0",
                          }}
                        >
                          <li className="breadcrumb-item">
                            <Link
                              to={"/"}
                              className="fsm-theme-small-text font-bold"
                            >
                              HOME
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link
                              to={"/blogs/"}
                              className="fsm-theme-small-text font-bold"
                            >
                              BLOGS
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <span
                              className="fsm-theme-small-text font-bold"
                              dangerouslySetInnerHTML={{
                                __html: post.title.substr(0, 50) + "...",
                              }}
                            />{" "}
                          </li>
                        </ol>
                      </nav>

                      <h1
                        className="fsm-theme-category-heading color-dark"
                        dangerouslySetInnerHTML={{ __html: post.title }}
                      />

                      <div className="row pt-4 pb-2">
                        <div className="col-auto">
                          <ul className="list-group list-group-horizontal list-style-type-none float-md-right border-0">
                            <li className="list-group-item bg-transparent p-0  border-0">
                              <Link
                                to={
                                  "https://www.facebook.com/sharer.php?u=" + url
                                }
                                target={"_blank"}
                              >
                                <div
                                  className="icon-circle border rounded-circle p-2 text-center"
                                  style={{ height: "30px", width: "30px" }}
                                >
                                  <i className="fa fa-facebook-f"> </i>
                                </div>
                              </Link>
                            </li>
                            <li className="list-group-item bg-transparent p-0  border-0">
                              <Link
                                to={
                                  "https://twitter.com/intent/tweet?url=" +
                                  url +
                                  "&text=" +
                                  tit
                                }
                                target={"_blank"}
                              >
                                <div
                                  className="icon-circle border rounded-circle p-2 text-center"
                                  style={{ height: "30px", width: "30px" }}
                                >
                                  <i className="fa-brands fa-x-twitter"> </i>
                                </div>
                              </Link>
                            </li>
                            <li className="list-group-item bg-transparent p-0  border-0">
                              <Link
                                to={
                                  "https://www.linkedin.com/shareArticle?url=" +
                                  url +
                                  "&title=" +
                                  tit
                                }
                                target={"_blank"}
                              >
                                <div
                                  className="icon-circle border rounded-circle p-2 text-center"
                                  style={{ height: "30px", width: "30px" }}
                                >
                                  <i
                                    className="fa fa-linkedin"
                                    style={{ top: "-6px" }}
                                  >
                                    {" "}
                                  </i>
                                </div>
                              </Link>
                            </li>
                            <li className="list-group-item bg-transparent p-0  border-0">
                              <a
                                href={
                                  "mailto:info@example.com?&subject=" +
                                  tit +
                                  "&cc=&bcc=&body=" +
                                  url
                                }
                              >
                                <div
                                  className="icon-circle border rounded-circle p-2 text-center"
                                  style={{ height: "30px", width: "30px" }}
                                >
                                  <i
                                    className="fa fa-envelope"
                                    style={{ left: "-1px", top: "-6px" }}
                                  >
                                    {" "}
                                  </i>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="pb-5">
                        {post.featured_media.localFile !== null ? (
                          <img
                            className="img-thumbnail"
                            src={
                              post.featured_media.localFile.childImageSharp
                                .resolutions.src
                            }
                            alt={post.title}
                          />
                        ) : (
                          ""
                        )}
                      </div> */}

                      <div className="pb-5">
                        {post.featured_media &&
                        post.featured_media.localFile ? (
                          <img
                            className="img-thumbnail"
                            src={
                              post.featured_media.localFile.childImageSharp
                                .resolutions.src
                            }
                            alt={post.title}
                          />
                        ) : (
                          <p>No featured image available</p> // Fallback if no image exist
                        )}
                      </div>

                      <div className="pb-4">
                        <InnerSnipCard data={post} />
                      </div>

                      <div>
                        <div
                          style={{ wordBreak: "break-word" }}
                          dangerouslySetInnerHTML={{
                            __html: globe.domainReplace(content),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="pos-sticky">
                        <div className="mb-5 pb-3 ">
                          <SmallContactForm />
                        </div>

                        <h2 className={"fsm-theme-heading theme-color-blue"}>
                          Food Regulatory Services
                        </h2>

                        <div>
                          <ul className="list-style-type-none ml-0 pl-0">
                            <li className="fsm-theme-text color-normal">
                              Consumer Product
                            </li>
                            <li className="fsm-theme-text color-normal">
                              Compliance Services
                            </li>
                            <li className="fsm-theme-text color-normal">
                              Licenses
                            </li>
                          </ul>
                        </div>

                        <div
                          className="mt-4"
                          style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#707070",
                          }}
                        >
                          {" "}
                        </div>

                        <TagsList data={post.tags} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<ScrollspySection/>*/}
      </main>

      <Footer />
      <FixedInfoButton />
      <SharePopupModel name={"shareModal"} />
      <PopupModel name={"formModal"} />
      <FormResponseModel />
    </>
  );
}
