import React from "react";
import $ from "jquery";
import { Link } from "gatsby";

class TableOfContent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let p = 0;
    $(".clickToGo").on("click", function() {
      $("html, body").animate(
        {
          scrollTop: $("#" + $(this).attr("data")).offset().top - 80,
        },
        1000
      );
    });

    var intersectionObserver = new IntersectionObserver(function(e) {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (e[0].intersectionRatio <= 0) {
        return;
      } else {
        $(".clickToGo")
          .closest("li")
          .removeClass("active");
        $(".clickToGo[data='" + e[0].target.id + "']")
          .closest("li")
          .addClass("active");
      }
    });

    let toc = $(".clickToGo") || [];
    for (let i = 0; i < toc.length; i++) {
      // Get the data attribute value
      let dataAttr = toc[i].getAttribute("data");

      // Use getElementById directly
      let element = document.getElementById(dataAttr);

      // If the element exists, observe it
      if (element) {
        intersectionObserver.observe(element);
      } else {
        console.warn(`Element not found for ID: ${dataAttr}`);
      }
    }
  }

  render() {
    return (
      <>
        <nav>
          <ul className="p-0 m-0 pl-3">
            {this.props.toc.map((e, i) => {
              return (
                <li
                  className="fsm-theme-text toc-container"
                  style={{ paddingBottom: "5px" }}
                >
                  <Link
                    className="clickToGo color-dark"
                    data={this.props.ids[i]}
                    to={"#" + this.props.ids[i]}
                  >
                    {e}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </>
    );
  }
}
export default TableOfContent;
